import { createSlice } from '@reduxjs/toolkit';


const couponSlice = createSlice({
  name: 'coupons',
  initialState: {
    coupons: [],
    selectedCoupon: null,
  appliedCoupon: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCoupons: (state, action) => {
      state.coupons = action.payload.coupons;
      state.loading = action.payload.loading;
      state.error = action.payload.error;
    },
    selectCoupon: (state, action) => {
      state.selectedCoupon = action.payload;
    },
    setAppliedCoupon: (state, action) => {
      state.appliedCoupon = action.payload;
    },
      removeCoupon: (state) => {
        state.selectedCoupon = null; // Clear the selected coupon
        state.appliedCoupon = null;
      },

  },
 
});

export const { setCoupons, selectCoupon, setAppliedCoupon, removeCoupon } = couponSlice.actions;
export default couponSlice.reducer;

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import storeReducer from './storeSlice';
import sessionReducer from './sessionSlice';
import otherReducer from './otherslice';
import cartReducer from './cartSlice';
import locationReducer from './locationSlice';
import customerDetailsReducer from './customerDetailsSlice';
import deliveryInstructionsReducer from './deliveryInstructionSlice';
import couponReducer from './couponSlice';
import orderSettingReducer from './orderSettingSlice';
import modalReducer from './modalSlice'

const rootPersistConfig = {
	key: 'root',
	storage,
	whitelist: [
		'store',
		'session',
		'other',
		'cart',
		'location',
		'userInfo',
		'instruction',
		'coupon',
		'orderSetting',
		'modal',
	],
};

const rootReducer = combineReducers({
	store: storeReducer,
	session: sessionReducer,
	other: otherReducer,
	cart: cartReducer,
	location: locationReducer,
	userInfo: customerDetailsReducer,
	instruction: deliveryInstructionsReducer,
	coupon: couponReducer,
	orderSetting: orderSettingReducer,
	modal: modalReducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}),
});

export const persistor = persistStore(store);

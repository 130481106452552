import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeOrderModal, setOrderID } from '../../store/modalSlice';
import REACTDOM from 'react-dom';
import { paths } from '../../routes';
import x from '../../assets/images/x2.svg';
import Button from '../StoreFront/components/button';

const OrderModal = ({ isOpen }) => {
	const { orderID } = useSelector((state) => state.modal);
	const [orderInput, setOrderInput] = useState(orderID);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleTrack = () => {
		dispatch(setOrderID(orderInput));
		navigate(`/order/${orderInput.trim()}`);
		dispatch(closeOrderModal());
	};

	if (!isOpen) return null; // Don't render modal if it's not open

	return (
		<div className='fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-9999'>
			<div className='bg-white rounded-lg p-6 shadow-lg w-[40%] z-50 '>
				<div className='flex justify-between items-center mb-8 border-b pb-2'>
					<h2 className='text-lg font-semibold '>Track Your Order</h2>
					<img
						src={x}
						alt='cancel'
						onClick={() => dispatch(closeOrderModal())}
						className='cursor-pointer bg-grey p-1 rounded-full'
					/>
				</div>
				<div className='flex gap-4 '>
					<input
						type='text'
						value={orderInput}
						onChange={(e) => setOrderInput(e.target.value)}
						className='w-2/3 px-4 py-2 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500'
						placeholder='Enter your order ID'
					/>

					<Button
						type='primary'
						label='Search'
						className='rounded-md'
						onClick={handleTrack}
					/>
				</div>
			</div>
		</div>
	);
};

export default OrderModal;

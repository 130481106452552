import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOrderModalOpen: false,
    orderID: '',

};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state) => {
            state.isOrderModalOpen = true;
            console.log("CLicked")

        },
        closeOrderModal: (state) => {
            state.isOrderModalOpen = false;
        },
        setOrderID: (state, action) => {
            state.orderID = action.payload;
        },
    },
});

export const {
    openModal,
    closeOrderModal,
    setOrderID
} = modalSlice.actions;

export default modalSlice.reducer;

import Amen from "../assets/images/Amen.png";
import Felix from "../assets/images/Felix.png";
import Firdaws from "../assets/images/Firdaws.png";
import Mo from "../assets/images/Mo.png";
import Marv from "../assets/images/Marv.png";
import Ambrose from "../assets/images/Ambrose.jpg";
import Fash from "../assets/images/Fashola.png";
import Fisayomi from "../assets/images/Fisayomi.png";
import Chidera from "../assets/images/Chidera.jpg";

export const Teamdata = [
  {
    img: Firdaws,
    name: "Firdaws Lamidi",
    role: "Engineering",
    link: "https://www.linkedin.com/in/firdaws-lamidi-840b57200/",
  },
  {
    img: Mo,
    name: "Motunrayo Megbodofo",
    role: "Product",
    link: "https://www.linkedin.com/in/motunrayomegbodofo/",
  },
  {
    img: Felix,
    name: "Felix Bissong",
    role: "Operations",
    link: " https://www.linkedin.com/in/felix-bissong-501448108",
  },
  {
    img: Fash,
    name: "Fashola Olatunji",
    role: "Sales",
    link: "https://www.linkedin.com/in/fashola-tunji-92311a136/",
  },
  {
    img: Ambrose,
    name: "Ambrose Uzoh",
    role: "Product",
    link: "https://www.linkedin.com/in/ambrose-uzoh-510719294/",
  },
  {
    img: Fisayomi,
    name: "Fisayomi Aiyetogbon",
    role: "Engineering",
    link: "http://linkedin.com/in/oluwafisayomii",
  },
  {
    img: Chidera,
    name: "Chidera Chiedu",
    role: "Product",
    link: "https://www.linkedin.com/in/chidera-chiedu",
  },
  {
    img: Marv,
    name: "Marvelous-Grace Oyeyemi",
    role: "Marketing",
    link: "http://linkedin.com/in/marvellousgraceoyeyemi"
  },
  {
    img: Amen,
    name: "Amen Olabode",
    role: "Product",
    link: " https://www.linkedin.com/in/olabodeamen/",
  },
];

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAPI } from '../../../../api/api_context';
import Button from '../button';
import EmptyCart from '../../../../assets/images/empty-cart.png';
import { FaTimes } from 'react-icons/fa';
import CartItem from '../CartItem';
import { cartOpen, setCart } from '../../../../store/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { numberWithCommas } from '../../utils/add_commas';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
	const apiservice = useAPI();

	const [loading, setLoading] = useState(true);
	const cartRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { storeIdentifier } = useSelector((state) => state.store);

	const { totalCartValue, products } = useSelector((state) => state.cart);
	const handleIncrement = async (product) => {
		try {
			const response = await apiservice.incrementProduct(product._id);

			dispatch(
				setCart({
					products: response.products,
					totalCartValue: response.totalCartValue,
					serviceCharge: response.serviceCharge,
					_id: response._id,
				}),
			);
		} catch (error) {
			console.error('Error incrementing product:', error);
		}
	};

	const handleDecrement = async (product) => {
		try {
			const response = await apiservice.decrementProduct(product._id);

			dispatch(
				setCart({
					products: response.products,
					totalCartValue: response.totalCartValue,
					serviceCharge: response.serviceCharge,
					_id: response._id,
				}),
			);
		} catch (error) {
			console.error('Error decrementing product:', error);
		}
	};

	const removeProduct = async (product) => {
		try {
			const response = await apiservice.removeFromCart(product._id);

			dispatch(
				setCart({
					products: response.products,
					totalCartValue: response.totalCartValue,
					serviceCharge: response.serviceCharge,
					_id: response._id,
				}),
			);
		} catch (error) {
			console.error('Error removing product:', error);
		}
	};
	const clearCart = async () => {
		try {
			const response = await apiservice.clearCart();

			dispatch(
				setCart({
					products: response.products,
					totalCartValue: response.totalCartValue,
					serviceCharge: response.serviceCharge,
					_id: response._id,
				}),
			);
		} catch (error) {
			console.error('Error removing product:', error);
		}
	};

	const handleCheckout = () => {
		const currentPath = window.location.pathname;
		const targetPath = `/${storeIdentifier}/checkout`;

		if (currentPath !== targetPath) {
			navigate(targetPath, { relative: 'path' });
		}
	};

	const handleContinueShopping = () => {
		const currentPath = window.location.pathname;
		const targetPath = `/${storeIdentifier}`;

		if (currentPath !== targetPath) {
			navigate(targetPath);
		}

		dispatch(cartOpen(false));
	};

	const fetchCartItems = useCallback(async () => {
		setLoading(true);
		try {
			const response = await apiservice.getCartItems();

			dispatch(
				setCart({
					products: response.products || [],
					totalCartValue: response.totalCartValue,
					serviceCharge: response.serviceCharge,
					_id: response._id,
				}),
			);
		} catch (error) {
			console.error('Error fetching cart items:', error);
		} finally {
			setLoading(false);
		}
	}, [apiservice, dispatch]);

	useEffect(() => {
		fetchCartItems();
	}, [fetchCartItems]);

	const cartSize = products?.reduce(
		(total, product) => total + product.quantity,
		0,
	);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (cartRef.current && !cartRef.current.contains(event.target)) {
				dispatch(cartOpen(false));
			}
		};
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [dispatch]);

	return (
		<div
			ref={cartRef}
			className='absolute top-6 right-0 md:w-[35vw] max-h-[75vh] overflow-y-auto bg-white border border-gray-200 rounded-lg shadow-xl z-50 overflow-hidden justify-end flex flex-col'
			onClick={(e) => e.stopPropagation()}>
			{/* Header */}
			<div className='flex justify-between items-center border-b px-4 py-3 border-gray-300 sticky top-0 bg-white z-10'>
				<h1 className='text-[18px] font-semibold'>
					Your Cart {cartSize > 0 && `(${cartSize} items)`}
				</h1>

				<button
					onClick={() => {
						dispatch(cartOpen(false));
					}}
					className=' text-gray-500 bg-formBG rounded-full p-2'>
					<FaTimes />
				</button>
			</div>

			{/* Loading Indicator */}
			{loading ? (
				<div className='flex justify-center items-center py-12'>
					<div className='spinner w-8 h-8 border-4 border-t-transparent border-gray-400 rounded-full animate-spin'></div>
				</div>
			) : (
				<div className='flex justify-center w-full items-center'>
					{!products || products.length === 0 ? (
						<div className='flex flex-col items-center justify-center py-12 px-8 w-2/3 text-center'>
							<img
								src={EmptyCart}
								alt='Empty cart'
								className='w-28 h-28 mb-4'
							/>
							<p className='text-sm text-gray-500'>
								Your cart is empty. Add items to start shopping!
							</p>
						</div>
					) : (
						<div className='w-full'>
							{products?.map((product) => (
								<CartItem
									key={product._id}
									product={product}
									handleDecrement={handleDecrement}
									handleIncrement={handleIncrement}
									removeProduct={removeProduct}
								/>
							))}
						</div>
					)}
				</div>
			)}

			<div className='sticky bottom-0 border-t border-gray-300 bg-white '>
				<div className='bg-grey flex p-4 justify-between'>
					<div className='text-[13px] text-secondary'>Subtotal</div>
					<div className='text-[13px] text-primary font-semibold'>
						NGN{' '}
						{numberWithCommas(totalCartValue)?.toLocaleString(2)}
					</div>
				</div>
				<div className='flex p-4 justify-between items-center'>
					<button
						className='text-[13px] text-secondary'
						onClick={clearCart}>
						Clear Cart
					</button>
					<div className='flex space-x-2 items-center'>
						<Button
							label='Continue Shopping'
							type='tertiary'
							size='S'
							onClick={handleContinueShopping}
							className='text-basegreen'
						/>
						<Button
							label='Checkout'
							onClick={handleCheckout}
							size='S'
							disabled={products?.length === 0} // Disable when cart is empty
							className={`${
								products?.length === 0
									? 'bg-gray-300 text-gray-500 cursor-not-allowed' // Disabled styles
									: 'bg-basegreen text-white' // Active styles
							}`}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cart;

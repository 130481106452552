import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	searchQuery: '',
	searchResults: [],
	searchSuggestions: [],
	loading: false,
	error: null,
	activeView: 'default',
	activeTab: 'all',
	selectedData: null,
	filteredProducts: [],
};

const otherSlice = createSlice({
	name: 'other',
	initialState,
	reducers: {
		setSearchQuery: (state, action) => {
			state.searchQuery = action.payload;
		},
		setSearchResults: (state, action) => {
			state.searchResults = action.payload;
			state.error = null; // Clear any existing errors when new results are set
		},
		setSearchSuggestions: (state, action) => {
			state.searchSuggestions = action.payload?.data;
		},
		setActiveView: (state, action) => {
			state.activeView = action.payload;
		},
		setActiveTab: (state, action) => {
			console.log('Setting activeTab:', action.payload);
			state.activeTab = action.payload;
		  },
		  setSelectedData: (state, action) => {
			const { type, data } = action.payload;
			state.selectedData = data;
			console.log('Setting selected data:', data);
			state.filteredProducts =
			type === 'promotions'
			? Array.isArray(data) ? data : []
			: Array.isArray(data?.products) ? data.products : [];
		  },
	},
});

export const {
	setSearchQuery,
	setSearchResults,
	setSearchSuggestions,
	setActiveView,
	setSelectedData,
	setActiveTab,
} = otherSlice.actions;

export default otherSlice.reducer;

import React, { useRef, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import clothing from '../../../assets/images/fashion 1.svg';
import electronics from '../../../assets/images/laptop 1.svg';
import footwear from '../../../assets/images/flip-flops 1.svg';
import { useAPI } from '../../../api/api_context';
import { setSearchResults, setActiveView } from '../../../store/otherslice';
import { useDispatch } from 'react-redux';

const SearchInput = ({ value, className }) => {
	const apiService = useAPI();
	const dispatch = useDispatch();
	const [suggestions, setSuggestions] = useState([]);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const [loadingSearch, setLoadingSearch] = useState(false);
	const [searchQuery, setSearchQuery] = useState(value || '');
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
	const searchInputRef = useRef(null);

	// const searchQuery = useSelector((state) => state.other.searchQuery);

	const handleInputChange = async (e) => {
		const query = e.target.value;
		setSearchQuery(query);

		if (query) {
			setLoadingSuggestions(true);
			try {
				const response = await apiService.getSuggestions(query);
				setSuggestions(response || []);
			} catch (error) {
				console.error('Error fetching suggestions:', error);
			} finally {
				setLoadingSuggestions(false);
			}
		} else {
			setSuggestions([]);
		}
	};

	const handleSuggestionClick = async (suggestion) => {
		setLoadingSearch(true);
		try {
			setSearchQuery(suggestion);
			const response = await apiService.searchStore(suggestion);
			await dispatch(setSearchResults(response));
			await dispatch(setActiveView('search_results'));
			toggleSearchModal();
		} catch (error) {
			console.error('Error fetching store data:', error);
		} finally {
			setLoadingSearch(false);
		}
	};

	const toggleSearchModal = () => {
		setIsSearchModalOpen(!isSearchModalOpen);
		if (!isSearchModalOpen) {
			setTimeout(() => searchInputRef.current?.focus(), 100);
		}
	};

	return (
		<>
			<div
				onClick={toggleSearchModal}
				className={`md:hidden`}>
				<IoSearch className='text-2xl cursor-pointer' />
			</div>
			<div
				onClick={toggleSearchModal}
				className={`md:flex hidden h-[40px] w-[144px] relative ${className}`}>
				<IoSearch className='absolute text-gray-400 left-4 top-[19.5px] transform -translate-y-1/2 z-10' />
				<input
					placeholder='Search'
					value={searchQuery}
					onChange={handleInputChange}
					type='text'
					className='pl-10 bg-formBG rounded-lg h-[40px] focus:ring-0 border border-outline block w-full placeholder:text-[13px] p-2.5'
				/>
			</div>

			{isSearchModalOpen && (
				<div
					onClick={toggleSearchModal}
					className='fixed inset-0 z-50 flex md:items-center justify-center bg-primary bg-opacity-50 md:overflow-hidden md:w-full items-end'>
					<div
						onClick={(e) => e.stopPropagation()}
						className='bg-white rounded-xl md:w-1/3 overflow-hidden gap-4 w-[90vw]'>
						<div className='relative w-full'>
							<IoSearch className='absolute text-gray-400 left-4 top-[27px] transform -translate-y-1/2 z-10' />
							<input
								type='text'
								ref={searchInputRef}
								value={searchQuery}
								onChange={handleInputChange}
								placeholder='What are you looking for?'
								className='pl-10 py-4 border-b border-0 focus:outline-none w-full placeholder:text-[15px] placeholder:leading-none'
								autoFocus
							/>
							{searchQuery.length > 0 && (
								<i
									className='fi fi-sr-circle-xmark absolute cursor-pointer right-4 top-[19px]'
									onClick={() => {
										setSearchQuery('');
										setSuggestions([]);
									}}></i>
							)}
						</div>

						{/* Conditional rendering for suggestions, loading, and empty states */}
						<div className='p-4'>
							{suggestions.length > 0 ? (
								<ul>
									{suggestions.map((result, index) => (
										<li
											key={index}
											className='flex items-center gap-2 border-b py-6 hover:bg-gray-100 cursor-pointer'
											onClick={() =>
												handleSuggestionClick(result)
											}>
											<IoSearch className='mr-3 text-gray-600' />
											<span className='text-gray-800'>
												{result}
											</span>
										</li>
									))}
								</ul>
							) : searchQuery && suggestions.length === 0 ? (
								<p className='py-2 text-gray-500'>
									No results found for "{searchQuery}"
								</p>
							) : (
								// Default content when no search is made
								<div className='flex flex-col gap-6'>
									<div className='flex flex-col gap-4'>
										{[
											{
												src: clothing,
												alt: 'Clothing',
											},
											{
												src: footwear,
												alt: 'Footwear',
											},
											{
												src: electronics,
												alt: 'Electronics',
											},
										].map((item, index) => (
											<div
												key={index}
												className='flex gap-6 bg-[#FAFAFA] px-2 py-4 rounded-lg'>
												<img
													src={item.src}
													alt={item.alt}
												/>
												<p>{item.alt}</p>
											</div>
										))}
									</div>
									<div className=''>
										<p className='text-secondary py-3 border-b text-[13px]'>
											Quick Actions
										</p>
										<div className='flex flex-col text-secondary mt-4 text-[15px] '>
											{[
												'View All Categories',
												'View All Products',
											].map((action, index) => (
												<p
													key={index}
													className='flex gap-8 px-2 py-4 items-center'>
													<i
														className={`fi ${
															index === 0
																? 'fi-sr-category flex items-center'
																: 'fi-sr-box-open flex items-center'
														}`}></i>
													<span className='flex-1'>
														{action}
													</span>
													<i className='fi fi-rr-arrow-small-right flex items-center'></i>
												</p>
											))}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SearchInput;

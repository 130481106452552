import React, { useState, useEffect, useRef } from 'react';
import { Menu, X, Search } from 'lucide-react';
import Avatar from './avatar';
import SearchInput from './search';
import Cart from './checkout/cart';
import { useDispatch, useSelector } from 'react-redux';
import { IoArrowBack } from 'react-icons/io5';
import { cartOpen } from '../../../store/cartSlice';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'

const StoreHeader = ({ headerType = 'store' }) => {
	const searchInputRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { products, isCartOpen } = useSelector((state) => state.cart);
	const storeData = useSelector((state) => state.store);
	const storeHeaderColor = storeData?.settings?.storeHeaderColor || '#ffffff';
	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const [isSearchOpen, setSearchOpen] = useState(false); // Mobile search state
	const cartSize = products?.reduce(
		(total, product) => total + product.quantity,
		0,
	);

	const handleBackButton = () => {
		navigate(-1);
	};

	// Toggle search input visibility on mobile
	const handleSearchToggle = () => {
		setSearchOpen(!isSearchOpen);
	};

	// Focus search input when opened
	useEffect(() => {
		if (isSearchOpen && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [isSearchOpen]);

	return (
		<>
			{headerType === 'store' && (
				<header
					className='z-50 flex p-4 border-b h-[64px] fixed w-full'
					style={{ backgroundColor: storeHeaderColor }}>
					{/* Left Section: Store Name, Avatar, Location */}
					<div className='max-w-[1080px] flex justify-between items-center w-full mx-auto'>
						<div className='flex items-center space-x-4'>
							<Menu className='w-4 h-4 cursor-pointer' onClick={() => setSidebarOpen(true)} />

							<div className='flex items-center space-x-10'>
								<div className='flex space-x-4 items-center'>
									<Avatar
										firstName={storeData?.storeName}
										imageSrc={storeData?.settings?.logo}
										size={28}
									/>
									<h1 className='text-md font-semibold leading-tight tracking-tight text-primary'>
										{storeData?.storeName}
									</h1>
								</div>
								<div className='space-y-1 hidden md:block'>
									<p className='text-[13px] text-secondary leading-none'>
										Store Address
									</p>
									<div className='text-[15px] '>
										{storeData?.location?.address}
									</div>
								</div>
							</div>
						</div>

						{/* Right Section: Search, Cart */}
						<div className='flex items-center '>
							<SearchInput  className='hidden md:block' />
							{/* Cart */}
							<div className='relative md:flex items-center ml-6 hidden '>
								<div className='relative'>
									<i
										className='fi fi-ss-bags-shopping flex items-center cursor-pointer'
										onClick={() =>
											dispatch(cartOpen(true))
										}></i>
									{cartSize > 0 && (
										<span className='absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full'>
											{cartSize}
										</span>
									)}
								</div>
							</div>
							<div className='relative'>
								{isCartOpen && <Cart />}
							</div>
								
						</div>
						
					</div>
				</header>
			)}

			{headerType === 'checkout' && (
				<div className='flex bg-white h-[64px] items-center fixed top-0 w-full border-b px-6 border-[#DEDEDE] '>
					<div className='max-w-[1080px] flex justify-center items-center w-full mx-auto'>
						<div
							onClick={handleBackButton}
							className='flex gap-4 items-center justify-center cursor-pointer'>
							<IoArrowBack className='' />
							<p className='hidden md:block   leading-[18px] text-primary -tracking-[3%]'>
								Back to store
							</p>
						</div>
						<h1 className='mx-auto text-primary   md:text-[20px] leading-[18px] md:leading-[23px]'>
							Checkout ({cartSize} items)
						</h1>
					</div>
				</div>
			)}
			{headerType === 'ordertracking' && (
				<div className='flex bg-white h-[64px] items-center fixed top-0 w-full border-b px-6 border-[#DEDEDE] '>
					<div className='max-w-[1080px] flex justify-between items-center w-full mx-auto'>
						<div
							onClick={handleBackButton}
							className='flex gap-4 items-center justify-center cursor-pointer'>
							<IoArrowBack className='' />
							<p className='hidden md:block   leading-[18px] text-primary -tracking-[3%]'>
								Back to store
							</p>
						</div>
						<h1 className='text-primary   md:text-[20px] leading-[18px] md:leading-[23px]'>
							Track Order
						</h1>
						
					<p className=' text-primary   md:text-[20px] leading-[18px] md:leading-[23px]'>
							Help
						</p>
					</div>
				</div>
			)}

			{/* Mobile Search Input */}
			{isSearchOpen && (
				<div className='fixed inset-0 bg-white z-50 p-4'>
					<div className='flex items-center justify-between'>
						<input
							ref={searchInputRef}
							type='text'
							placeholder='Search products...'
							className='w-full px-4 py-2 border-b focus:outline-none'
						/>
						<X
							className='w-6 h-6 cursor-pointer'
							onClick={handleSearchToggle}
						/>
					</div>
				</div>
			)}
			{isSidebarOpen && (
			<Sidebar
				isOpen={isSidebarOpen}
 					onClose={() => setSidebarOpen(false)}
					 					storeData={storeData}
 					
 				/>
 			)}
		</>
	);
};

export default StoreHeader;

 
import React from 'react';
import ModalHeader from './modalHeader';
import Button from './button';
import { numberWithCommas } from '../utils/add_commas';
import { useSelector } from 'react-redux';
import receiptGif from '../../../assets/images/receipt.gif';

const PaymentSuccessModal = ({ amountPaid, onClose }) => {
	const storeData = useSelector((state) => state.store);

	return (
		<div onClick={(e) => e.stopPropagation()}>
			<ModalHeader
				onClick={onClose}
				className={`px-6`}
			/>
			<div className='flex flex-col items-center p-6 gap-8'>
				<div className='flex flex-col items-center gap-4'>
					<img
						src={receiptGif}
						alt='Payment Successful'
						className='w-[120px] h-[120px]'
					/>
					<h1 className='font-medium text-primary text-center w-[70%]'>
						Your payment of NGN {numberWithCommas(amountPaid)} to{' '}
						{storeData.storeName} has been confirmed.
					</h1>
				</div>
				<div className=' w-full'>
					<Button
						label='Continue'
						className='w-full'
						onClick={onClose}
					/>
				</div>
			</div>
		</div>
	);
};

export default PaymentSuccessModal;

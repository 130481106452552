import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Avatar from "./avatar";
import x from "../../../assets/images/x2.svg";
import track from "../../../assets/images/track.svg";
import promo from "../../../assets/images/ticket-alt 1.svg";
import cart from "../../../assets/images/cart.svg";
import category from "../../../assets/images/category.svg";
import help from "../../../assets/images/help.svg";
import { cartOpen } from '../../../store/cartSlice';
// import { openModal } from '../../../store/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { setActiveView, setSelectedData, setActiveTab } from "../../../store/otherslice";
import { paths } from "../../../routes";
import { closeModal, openModal } from "../../../store/modalSlice";


const Sidebar = ({ isOpen, onClose, storeData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const { activeView, activeTab, selectedData } = useSelector((state) => state.other)
  console.log(activeView)
  const { isOrderModalOpen } = useSelector((state) => state.modal)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCartModal = () => {
    onClose();
    dispatch(cartOpen(true))

  }



  // const handleCategoryClick = (categorySlug) => {
  //   console.log(`Changing activeView to: ${categorySlug}`);
  //   dispatch(setActiveView(categorySlug)); // Dispatch the action to update the active view
  //   setIsDropdownOpen(false); // Close dropdown after clicking
  //   onClose(); // Optionally close the sidebar
  // };

  const handleCategoryClick = (category) => {
    dispatch(setActiveTab(category.categorySlug));
    console.log(activeTab)
    dispatch(
      setSelectedData({
        type: 'all_category',
        data: category,
      })
    );
    dispatch(setActiveView('all_category'));
    setIsDropdownOpen(false);
    onClose()
  };



  return (
    <div
      className={`fixed top-0 z-50 left-0 h-screen w-[25%] bg-white shadow-lg transform py-8 px-6 ${isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
    >
      <div className="flex justify-between items-center gap-4">
        <div className="flex items-center space-x-2 gap-2">
          <Avatar
            firstName={storeData?.storeName}
            imageSrc={storeData?.settings?.logo}
            size={32}
          />
          <h1 className="text-lg font-semibold leading-tight tracking-tight text-primary">
            {storeData?.storeName}
          </h1>
        </div>
        <button className="" onClick={onClose}>
          <img src={x} alt="cancel" srcset="" />
        </button>
      </div>

      <div className="mt-10 px-6">
        <ul className="flex flex-col gap-8  text-lg bg-red-500 relative">
          <a onClick={handleCartModal}>
            <li className="cursor-pointer flex items-center gap-4 bg-red-500">
              <img src={cart} className="text-red-500" /> Cart
            </li>
          </a>
          <Link >
            <li className="cursor-pointer flex items-center gap-4">
              <img src={promo} className="text-red-500" /> Promotions
            </li>
          </Link>

          <li className="cursor-pointer flex items-center gap-4 relative" onClick={() => {

            setIsDropdownOpen(!isDropdownOpen);
          }}>
            <img src={category} />
            <span className="">Categories</span>
            <span className="bg-white">

              {isDropdownOpen ? (
                <FaChevronUp />
              ) : (
                <FaChevronDown />
              )}
            </span>
            {isDropdownOpen && (
              <div className="absolute top-10 left-0 mt-2 z-10 w-[100%] flex flex-col gap-2">
                {storeData?.categories?.map((category) => (
                  <div
                    key={category._id}
                    className={` gap-6 bg-[#FAFAFA] px-4 py-3 rounded-lg cursor-pointer w-full mx-auto text-black ${category.categorySlug === storeData?.activeCategory
                      ? `border-[${storeData?.settings?.buttonColor}] border-b-2`
                      : 'text-[#7B8783]'
                      }`}
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category.name}
                  </div>
                ))}
              </div>
            )}
          </li>
          <li className="cursor-pointer flex items-center gap-4 relative" onClick={() => {

            setIsDropdownOpen2(!isDropdownOpen2);
          }}>
            <img src={help} />
            <span className="">Help</span>
            <span className="bg-white">

              {isDropdownOpen2 ? (
                <FaChevronUp />
              ) : (
                <FaChevronDown />
              )}
            </span>
            {isDropdownOpen2 && (
              <div className="absolute top-10 left-0 mt-2 z-10 w-[100%] flex flex-col gap-2">
                <div className="gap-6 bg-[#FAFAFA] px-4 py-3 rounded-lg cursor-pointer w-full mx-auto text-black">Help Center               </div>
                <div className="gap-6 bg-[#FAFAFA] px-4 py-3 rounded-lg cursor-pointer w-full mx-auto text-black">Whatsapp                </div>
                <div>
                  <a
                    href="https://wa.me/2347045533534?text=Hello%20I%20would%20like%20to%20know%20more%20about%20your%20services."
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="gap-6 bg-[#FAFAFA] px-4 py-3 rounded-lg cursor-pointer w-full mx-auto text-black border-b-2 text-[#7B8783]">
                      +234 7045533534
                    </div>
                  </a>
                </div>

              </div>
            )}
          </li>



          <li className="cursor-pointer flex items-center gap-4" onClick={() => {
            console.log('Track Order clicked');
            // setIsModalOpen(true)
            dispatch(openModal())
            onClose()
          }}>

            <img src={track} className="text-red-500" /> Track Order
          </li>

        </ul>
      </div>
      {/* {isModalOpen && <OrderModal isOpen={isModalOpen} closeModal={closeModal}/>} */}
    </div >
  );
};

export default Sidebar;

import React from 'react';
import InputField from './input_field';
import Button from './button';
import ModalHeader from './modalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useAPI } from '../../../api/api_context';
import { addCustomerDetails } from '../../../store/customerDetailsSlice';
import { toast } from 'sonner';

const DeliveryDetails = ({ setIsDetailsModalOpen }) => {
	const apiservice = useAPI();
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.userInfo);

	const [formData, setFormData] = React.useState({
		name: userInfo?.name || '',
		email: userInfo?.email || '',
		phone_number: userInfo?.phone_number || '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSave = async () => {
		const { name, email, phone_number } = formData;
		const isDataChanged =
			name !== userInfo?.name ||
			email !== userInfo?.email ||
			phone_number !== userInfo?.phone_number;

		if (!isDataChanged) {
			toast.info('No changes detected');
			setIsDetailsModalOpen(false);
			return;
		}

		try {
			const response = await apiservice.addCustomerDetails(
				name,
				email,
				phone_number,
			);
			dispatch(
				addCustomerDetails({
					name: response.name,
					email: response.email,
					phone_number: response.phone_number,
				}),
			);
			toast.success('Details updated successfully');
			setIsDetailsModalOpen(false);
		} catch (error) {
			toast.error('Error updating details');
			console.error('Error:', error);
		}
	};
console.log(userInfo);
	React.useEffect(() => {
		setFormData({
			name: userInfo?.name || '',
			email: userInfo?.email || '',
			phone_number: userInfo?.phone_number || '',
		});
	}, [userInfo]);
	return (
		<div
			className='fixed inset-0 bg-primary bg-opacity-50 z-50 flex items-center justify-center modal-overlay'
			onClick={() => setIsDetailsModalOpen(false)}>
			<div
				onClick={(e) => e.stopPropagation()}
				className='bg-white rounded-lg max-w-md w-full mx-4 relative'>
				<ModalHeader
					header='Your details'
					onClick={() => setIsDetailsModalOpen(false)}
				/>
				<div className='flex flex-col gap-4 w-full p-4'>
					<InputField
						label='Full Name'
						value={formData.name}
						handleValue={(value) =>
							handleChange({ target: { name: 'name', value } })
						}
						placeholder='ex. Andre Smith'
						name='name'
						type='text'
					/>

					<InputField
						label='Email Address'
						value={formData.email}
						handleValue={(value) =>
							handleChange({ target: { name: 'email', value } })
						}
						placeholder='ex. andre@smith.co'
						name='email'
						type='email'
					/>
					<InputField
						label='Phone Number'
						value={formData.phone_number}
						handleValue={(value) =>
							handleChange({
								target: { name: 'phone_number', value },
							})
						}
						placeholder='ex. 08000000000'
						name='phone_number'
						type='number'
					/>
					<Button
						type='primary'
						label={'Done'}
						className='w-full '
						borderRadius='rounded-[100px]'
						onClick={handleSave}
					/>
				</div>
			</div>
		</div>
	);
};

export default DeliveryDetails;

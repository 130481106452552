import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name: '',
	email: '',
	phone_number: '',
	location: {},
	orders: [],
	sessionHistory: [],
	status: 'idle',
};

const customerDetailsSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		addCustomerDetails: (state, action) => {
			const { name, email, phone_number } = action.payload;
			state.name = name;
			state.email = email;
			state.phone_number = phone_number;
			state.status = 'succeeded';
		},
		resetCustomerDetails: () => initialState,
	},
});

export const { addCustomerDetails, resetCustomerDetails } =
	customerDetailsSlice.actions;
export default customerDetailsSlice.reducer;

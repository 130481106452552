import { store } from '../store';
import { api } from './api_helper';

class ApiService {
	constructor() {
		this.updateMerchantIdFromStore();
		this.updateSessionIdFromStore();

		store.subscribe(() => {
			this.updateMerchantIdFromStore();
			this.updateSessionIdFromStore();
		});
	}

	updateMerchantIdFromStore() {
		const state = store.getState();
		this.storeIdentifier = state.store.storeIdentifier || '';
	}

	updateSessionIdFromStore() {
		const state = store.getState();
		this.sessionId = state.session.sessionId || '';
	}

	getStoreIdentifier() {
		return this.storeIdentifier;
	}

	getSessionId() {
		return this.sessionId;
	}

	async initiateSession() {
		try {
			const response = await api.post('/store/session/initiate', {
				withCredentials: true,
			});
			this.sessionId = response.data.data.sessionId;
			localStorage.setItem('sessionId', this.sessionId);
			return this.sessionId;
		} catch (error) {
			this.handleApiError('Error initiating session', error);
		}
	}

	async getStore(storeIdentifier) {
		try {
			const response = await api.get(`/store/get/${storeIdentifier}`, {
				withCredentials: true,
				headers: {
					sessionid: this.sessionId,
				},
			});
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error fetching store', error);
		}
	}
	async getProduct(storeIdentifier, productId) {
		try {
			const response = await api.get(
				`/store/get/${storeIdentifier}/products/${productId}`,
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);
			// console.log(response);
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error fetching store', error);
		}
	}
	async getSuggestions(query) {
		try {
			const params = new URLSearchParams({ query });

			const response = await api.get(
				`/store/${
					this.storeIdentifier
				}/searchsuggestion?${params.toString()}`,
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error fetching suggestions', error);
		}
	}
	async searchStore(query) {
		try {
			const params = new URLSearchParams({ query });

			const response = await api.get(
				`/store/${
					this.storeIdentifier
				}/search/products?${params.toString()}`,
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);
			// console.log(response.data.data)
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error fetching suggestions', error);
		}
	}

	//Get Cart Items
	async getCartItems() {
		try {
			const response = await api.get(`/store/cart`, {
				withCredentials: true,
				headers: {
					sessionid: this.sessionId,
				},
			});
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error fetching cart items', error);
		}
	}
	async getCoupons() {
		try {
			const response = await api.get(
				`/store/${this.storeIdentifier}/coupons`,
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);

			return response.data;
		} catch (error) {
			this.handleApiError('Error fetching cart items', error);
		}
	}

	async applyCoupon(couponCode) {
		try {
			const response = await api.post(
				`/store/cart/applycoupon`,
				{
					couponCode: couponCode,
				},
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);

			return response.data;
		} catch (error) {
			this.handleApiError('Error applying coupon', error);
		}
	}
	async removeCoupon(couponCode) {
		try {
			const response = await api.post(
				`/store/cart/removecoupon`,
				{},
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);

			return response.data;
		} catch (error) {
			this.handleApiError('Error removing coupon', error);
		}
	}

	async addToCart(productId, quantity, attributes) {
		try {
			const response = await api.put(
				`/store/cart/add`,
				{
					productId: productId,
					quantity: quantity,
					attributes: attributes,
				},
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);

			return response.data;
		} catch (error) {
			this.handleApiError('Error adding product to cart', error);
		}
	}
	async createOrder() {
		try {
			const response = await api.post(
				`/order/create`,
				{},
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);

			console.log(response);
			const { customOrderId } = response.data.data; // Extract customOrderId
			localStorage.setItem('OrderId', customOrderId); // Store in localStorage
			console.log(
				'Custom Order ID stored in localStorage:',
				customOrderId,
			);
			return response.data;
		} catch (error) {
			this.handleApiError('Error adding product to cart', error);
			return error;
		}
	}
	async incrementProduct(productId) {
		try {
			const response = await api.patch(
				`/store/cart/increase/${productId}`,
				{},
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);

			return response.data.data;
		} catch (error) {
			this.handleApiError('Error adding product to cart', error);
		}
	}

	async decrementProduct(productId) {
		try {
			const response = await api.patch(
				`/store/cart/decrease/${productId}`,
				{},
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);

			return response.data.data;
		} catch (error) {
			this.handleApiError('Error adding product to cart', error);
		}
	}

	async removeFromCart(productId) {
		try {
			const response = await api.delete(
				`/store/cart/remove/${productId}`,

				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);
			// console.log(response);
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error removing product from cart', error);
		}
	}
	async clearCart() {
		try {
			const response = await api.delete(
				`/store/cart/clear`,

				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);
			// console.log(response);
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error removing product from cart', error);
		}
	}
	async clearCartProducts() {
		try {
			const response = await api.delete(`/store/cart/clear`, {
				withCredentials: true,
				headers: {
					sessionid: this.sessionId,
				},
			});
			return response.data.cart;
		} catch (error) {
			this.handleApiError('Error clearing product from cart', error);
		}
	}

	getCurrentUserLocation = async (latitude, longitude) => {
		try {
			const response = await api.get(
				`/location/${longitude}/${latitude}/withdetails`,
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);

			this.trackApiCall(
				'GET',
				`/location/${longitude}/${latitude}/withdetails`,
				true,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/location/${longitude}/${latitude}`,
				false,
			);
			this.handleApiError('Error fetching user location', error);
		}
	};

	getAddressCoordinates = async (address) => {
		try {
			const response = await api.post(
				`/location/getlatlong`,
				{ address: address },
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);

			this.trackApiCall('POST', `/location/getlatlongs`, true);
			return response.data;
		} catch (error) {
			this.trackApiCall('POST', `/location/getlatlong`, false);
			this.handleApiError('Error fetching user location', error);
		}
	};

	async searchAddresses(address) {
		try {
			const response = await api.post(
				'/location/search',
				{
					searchInput: address,
				},
				{ withCredentials: true },
			);
			this.trackApiCall('POST', '/location/search');
			return response.data.addresses;
		} catch (error) {
			this.trackApiCall('POST', '/location/search', false);
			this.handleApiError('Error searching addresses', error);
		}
	}

	handleApiError(message, error) {
		console.error(
			`${message}:`,
			error.response?.data?.message || error.message,
		);
		throw new Error(`${message}. Please try again later.`);
	}

	trackApiCall(method, endpoint, success = true) {
		if (window.gtag) {
			window.gtag(
				'event',
				success ? 'api_call_success' : 'api_call_failure',
				{
					event_category: 'API',
					event_label: `${method} ${endpoint}`,
					value: success ? 1 : 0,
				},
			);
		}
	}
	// Add Customer details
	async addCustomerDetails(name, email, phone_number) {
		try {
			const response = await api.post(
				`/store/checkout/customerinfo`,
				{
					name,
					email,
					phone_number,
				},
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error adding customer details', error);
		}
	}

	// Get Customer details
	async getCustomerDetails(customerId) {
		try {
			const response = await api.get(
				`/store/customer/${customerId}/info`,
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error fetching customer details', error);
		}
	}

	// Add Delivery Instructions
	async addDeliveryInstructions(note, alternatePhone, closestLandmark) {
		try {
			const response = await api.post(
				`/store/checkout/updatedeliveryinstruction`,
				{
					note,
					alternatePhone,
					closestLandmark,
				},
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error adding delivery instruction', error);
		}
	}

	async addOrderSettings({
		orderUsesPIN,
		orderUsesEscrow,
		deliveryTiming,
		scheduledDateTime,
	}) {
		try {
			const response = await api.post(
				`/store/checkout/updateordersettings`,
				{
					orderUsesPIN,
					orderUsesEscrow,
					deliveryTiming,
					scheduledDateTime,
				},
				{
					withCredentials: true,
					headers: {
						sessionid: this.sessionId,
					},
				},
			);
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error adding delivery instruction', error);
		}
	}

	//Verify Payment
	async verifyPayment(reference) {
		try {
			const response = await api.get(`/payment/verify/${reference}`, {
				withCredentials: true,
				headers: {
					sessionid: this.sessionId,
				},
			});
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error verifying payment', error);
		}
	}

	async handleOrderTracking(orderId) {
		try {
			const response = await api.get(`/tracking/${orderId}`, {
				withCredentials: true,
				headers: {
					sessionid: this.sessionId,
				},
			});
			console.log(response);
			return response.data.data;
		} catch (error) {
			this.handleApiError('Error getting tracking details', error);
		}
	}
}

export default ApiService;

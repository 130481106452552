import React, { useState, useEffect } from "react";
import noCoupon from "../../../../assets/images/no-coupon.png";
import { BsFillTicketFill } from "react-icons/bs";
import Button from "../button.js";
import ModalHeader from "../modalHeader.js";
import PromotionCard from "../promotioncard.js";
import { useAPI } from "../../../../api/api_context.js";
import { useDispatch, useSelector } from "react-redux";
import { setCoupons, selectCoupon, setAppliedCoupon} from "../../../../store/couponSlice.js";
import { toast } from "sonner";

const Coupon = ({
  setCouponModalOpen,
}) => {
  const dispatch = useDispatch();
  const { coupons, loading, selectedCoupon } = useSelector(
    (state) => state.coupon
  );
  const apiService = useAPI();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const couponsData = await apiService.getCoupons();
        dispatch(
          setCoupons({
            coupons: couponsData.data,
            loading: false,
            error: null,
          })
        );
      } catch (error) {
        dispatch(
          setCoupons({
            coupons: [],
            loading: false,
            error: "Failed to load coupons.",
          })
        );
        console.error("Error fetching coupons:", error);
      }
    };

    fetchCoupons();
  }, [apiService, dispatch]);

 

  const handleApplyCoupon = async () => {
    const couponCode = selectedCoupon?.code ;
  
    if (!couponCode) {
      toast.error("Please select or enter a valid coupon code.");
      return;
    }
  
    try {
      const response = await apiService.applyCoupon(couponCode);
      dispatch(setAppliedCoupon(response.data)); 
      toast.success(`Coupon "${couponCode}" applied successfully.`);
      setCouponModalOpen(false); // Close modal
    } catch (error) {
      console.error("Error applying coupon:", error);
      toast.error("Failed to apply coupon. Please try again.");
    }
  };
  

   // Filter coupons based on search query
   const filteredCoupons = coupons.filter((coupon) => {
    const query = searchQuery.toLowerCase();
    return (
      coupon.code?.toLowerCase().includes(query) ||
      coupon.couponType?.toLowerCase().includes(query) ||
      String(coupon.discountValue).includes(query)
    );
  });

  return (
    <div
      onClick={() => setCouponModalOpen(false)}
      className="fixed inset-0 flex items-center justify-center bg-primary bg-opacity-90 z-50 modal-overlay"
    >
      <div
        className="bg-white rounded-lg max-w-md w-full mx-4 relative max-h-[90%] overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader
          header="Promotions"
          onClick={() => setCouponModalOpen(false)}
        />

        <div className="flex p-4 gap-2 items-center justify-center w-full">
	
					<div className='flex items-center justify-between relative w-full'>
						<input
							type='text'
							placeholder='Enter promo code here'
							name="coupon"
							className='w-full pl-10 pr-6 py-3 rounded-[8px] border border-[#CECECE] bg-[#FAFAFA] outline-primary focus:outline-none focus:border-none font-[400] text-[15px] leading-[20px] text-[#AAB1AF]'
							value={ searchQuery || ''}
            onChange={(e) => setSearchQuery(e.target.value || '')}
						/>
						<BsFillTicketFill className="ml-4 absolute left-0 w-[16px] h-[16px] " />
					</div>
			
          <Button
            label="Apply"
            className="w-fit rounded-xl"
            onClick={(e) => {
              handleApplyCoupon();
            }}
          />
        </div>
        <div className="space-y-4 px-4 pb-4">
          <h3 className="text-secondary text-[14px]">Available Promotions</h3>
          {loading ? (
            <div className="flex justify-center items-center py-12">
              <div className="spinner w-8 h-8 border-4 border-t-transparent border-gray-400 rounded-full animate-spin"></div>
            </div>
          ) : filteredCoupons.length === 0 ? (
            <div className="flex flex-col gap-8 items-center justify-center">
              <img
                src={noCoupon}
                alt="No coupon available"
                className="w-[96px] h-[96px]"
              />
              <p className="text-center leading-[21px] text-primary">
                You don’t have any promotions at this time
              </p>
            </div>
          ) : (
            <div className="flex flex-col gap-3 max-h-[350px] overflow-y-scroll overflow-hidden no-scrollbar">
              {filteredCoupons
                .filter((promo) => promo.active)
                .map((promo) => (
                  <div key={promo._id} style={{ scrollSnapAlign: "start" }}>
                    <PromotionCard
                      code={promo.code}
                      discountValue={promo.discountValue}
                      expiry={promo.expiry}
                      maxUsage={promo.maxUsage}
                      active={promo.active}
                      couponType={promo.couponType}
                      onClick={async () => {
                try {
                  // Select the coupon and apply immediately
                  dispatch(selectCoupon(promo)); // Update Redux state
                  const response = await apiService.applyCoupon(promo.code); // Directly apply coupon
                  console.log('Response', response);
                  dispatch(setAppliedCoupon(response.data));
                  toast.success(`Coupon "${promo.code}" applied successfully.`);
                  setCouponModalOpen(false); // Close modal
                } catch (error) {
                  console.error("Error applying coupon:", error);
                  toast.error("Failed to apply coupon. Please try again.");
                }
              }}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Coupon;

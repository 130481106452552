import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { MdPhoneIphone } from 'react-icons/md';
import Layout from '../../Layouts/StoreLayout';
import { useWebSocket } from '../../utils/websocketContext';
import { useAPI } from '../../api/api_context';
import Package from '../../assets/images/package.svg';
import CartSummary from '../StoreFront/components/cartSummary';
import { numberWithCommas } from '../StoreFront/utils/add_commas';
import Button from '../StoreFront/components/button';

const OrderTracking = () => {
	const ws = useWebSocket();
	const apiservice = useAPI();
	const { customOrderId } = useParams();

	const [order, setOrder] = useState(null);

	// Fetch order details
	const handleGetOrderDetails = useCallback(async () => {
		try {
			const response = await apiservice.handleOrderTracking(
				customOrderId,
			);
			setOrder(response?.order);
		} catch (error) {
			console.error('Failed to fetch order details:', error);
		}
	}, [apiservice, customOrderId]);

	// WebSocket message handler
	useEffect(() => {
		if (!ws) return;

		const handleWebSocketMessage = (event) => {
			try {
				const { event: eventType, payload } = JSON.parse(event.data);
				console.log('WebSocket Event:', eventType, payload);
			} catch (error) {
				console.error('WebSocket message error:', error);
			}
		};

		ws.onmessage = handleWebSocketMessage;

		return () => {
			ws.onmessage = null;
		};
	}, [ws]);

	// Fetch order details on mount
	useEffect(() => {
		handleGetOrderDetails();
	}, [handleGetOrderDetails]);

	// Format date helper
	const formattedDate = (isoString) => {
		const date = new Date(isoString);
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		};
		return date.toLocaleString(undefined, options);
	};
	const callCourier = () => {
		if (order?.courier?.phone_number) {
			window.open(`tel:${order.courier.phone_number}`);
		}
	};
	return (
		<Layout
			headerType='ordertracking'
			background='bg-grey'>
			<div className='w-full bg-grey min-h-screen p-6 grid grid-cols-12 gap-6'>
				{/* Left section: Order Tracking */}
				<div className='col-span-8  flex flex-col gap-4'>
					{/* Order Header */}
					<div className='bg-white p-6 rounded-md'>
						<div className='flex flex-col gap-4 border-b pb-4 mb-6 '>
							<div className='flex gap-2 items-center'>
								<img
									src={Package}
									alt='Package'
									className='h-6 w-6'
								/>
								<p className='font-semibold text-lg'>
									{order?.customId || 'Loading...'}
								</p>
							</div>
							<div className='text-basegreen font-semibold bg-basegreen/10 px-4 py-2 rounded-full w-fit'>
								{order?.currentStatus || 'Unknown Status'}
							</div>
							<div className='text-secondary'>
								{formattedDate(order?.createdAt) || 'N/A'}
							</div>
						</div>
						{/* delivery details */}
						<div className=''>
							<h1 className='border-b p-4'>Delivery Details</h1>
							{/* From & Destination */}

							<div className='flex items-start gap-4 mt-4'>
								{/* Timeline Indicator */}
								<div className='flex flex-col items-center'>
									<div className='w-3 h-3 bg-gray-400 rounded-full'></div>
									<div className='h-[60px] w-0.5 bg-gray-300'></div>
									<div className='w-3 h-3 bg-gray-400 rounded-full'></div>
								</div>

								{/* Address Information */}
								<div className='flex flex-col'>
									<div>
										<p className='text-sm text-gray-400'>
											Pickup location
										</p>
										<p className='mb-4'>
											{order?.merchant?.store?.location
												?.address || 'N/A'}
										</p>
									</div>
									<div>
										<p className='text-sm text-gray-400'>
											Delivery Address
										</p>
										<p className='mb-8'>
											{order?.customer?.location
												?.address || 'N/A'}
										</p>
									</div>
								</div>
							</div>

							<p className='border-b my-4 p-4'>
								Courier Information
							</p>
							{/* Courier Info */}
							<div className='flex items-center space-x-3 mb-4'>
								<img
									src='https://via.placeholder.com/40'
									alt='Courier'
									className='w-10 h-10 rounded-full object-cover'
								/>
								<div>
									<p className=''>
										{order?.courier?.name ||
											'Awaiting Courier Assignment'}
									</p>
									{/* <p className='text-sm text-gray-500'>Courier</p> */}
								</div>

								{order?.courier?.phone_number && (
									<Button
										type='primary'
										className='rounded-md'
										iconLeft={<MdPhoneIphone size={20} />}
										label='Call Courier'
										onClick={callCourier}
									/>
								)}
							</div>
						</div>
					</div>
					{/* Tracking Timeline */}
					<div className='space-y-8 bg-white p-6 rounded-md'>
						<h1 className='border-b pb-4'>Tracking Timeline</h1>
						{order?.tracking?.length > 0 ? (
							order.tracking.map((track, index) => (
								<div
									key={track._id || index}
									className='flex items-start space-x-4'>
									{/* Timeline marker */}
									<div className='flex flex-col items-center'>
										<div
											className={`h-3 w-3 rounded-full ${
												index ===
												order.tracking.length - 1
													? 'border-2 border-green-500 bg-white'
													: 'bg-green-500'
											}`}></div>
										{index < order.tracking.length - 1 && (
											<div className='h-12 w-0.5 bg-gray-300'></div>
										)}
									</div>

									{/* Tracking event details */}
									<div>
										<p className='font-semibold'>
											{track?.event || 'Unknown Event'}
										</p>
										<p className='text-sm text-gray-500'>
											{formattedDate(track?.timestamp) ||
												'N/A'}
										</p>
										<p className='text-sm text-gray-400'>
											{track?.location?.address ||
												'No location available'}
										</p>
									</div>
								</div>
							))
						) : (
							<p className='text-gray-500'>
								No tracking information available.
							</p>
						)}
					</div>
				</div>

				{/* Right section: Order Details */}
				<div className='col-span-4 bg-white p-6 rounded-md'>
					{/* Cart Info */}
					{order?.products.map((item, index) => (
						<div
							key={index}
							className={`${
								index === order?.products.length - 1
									? 'border-none'
									: 'border-b border-outline mb-2'
							} flex justify-between items-center pb-6 `}>
							{/* <CartSummary item={item} /> */}
							<>
								<div className='flex items-center gap-4'>
									{/* <img
																	src={item.images[0]}
																	alt={`Product ${item.name}`}
																	className='w-[64px] h-[64px] object-cover rounded'
																/> */}

									<div className='flex flex-col gap-2 '>
										<h2 className='text-primary font-medium text-[18px] leading-[21px] -tracking-[3%]'>
											{item?.product?.name}
										</h2>
										<p className='text-secondary  text-[13px] leading-[16px] -tracking-[3%]'>
											NGN{' '}
											{numberWithCommas(
												item?.product?.price *
													item?.quantity,
											)}
										</p>
									</div>
								</div>
								<div className='flex items-center gap-4'>
									<p className='text-secondary  text-[13px] leading-[18px] -tracking-[3%]'>
										Qty
									</p>
									<div className='w-auto h-[26px] rounded-[8px] py-1 px-2 bg-[#F5F5F5] border border-[#CECECE] flex justify-center items-center text-primary  text-[13px] leadin-[18px] -tracking-[3%]'>
										{item.quantity}
									</div>
								</div>
							</>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
};

export default OrderTracking;

import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
	useRef,
} from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
	const [ws, setWs] = useState(null);
	const socketRef = useRef(null);

	const createWebSocket = useCallback(() => {
		const isTestEnv = process.env.REACT_APP_ENV === 'test';

		const host = isTestEnv
			? 'localhost:1222'
			: process.env.REACT_APP_CENTRAL_SERVER;

		const wsProtocol = host.includes('localhost') ? 'ws://' : 'wss://';

		const socketUrl = `${wsProtocol}${host}`;

		console.log(`WebSocket URL: ${socketUrl}`);

		if (socketRef.current) {
			socketRef.current.close();
		}

		const socket = new WebSocket(socketUrl);

		socket.onopen = () => {
			console.log('WebSocket connection established');
			socketRef.current = socket;
			setWs(socket);
		};

		socket.onclose = () => {
			console.log('WebSocket connection closed');

			// Attempt reconnection after 10 seconds
			setTimeout(createWebSocket, 10000);
		};

		socket.onerror = (error) => {
			console.error('WebSocket error:', error);
			socket.close();
		};
	}, []);

	useEffect(() => {
		createWebSocket();

		return () => {
			if (socketRef.current) {
				socketRef.current.close();
			}
		};
	}, [createWebSocket]);

	useEffect(() => {
		if (!ws) return;

		// Send periodic pings to keep connection alive
		const pingInterval = setInterval(() => {
			if (ws.readyState === WebSocket.OPEN) {
				try {
					ws.send(JSON.stringify({ type: 'ping' }));
				} catch (error) {
					console.error('Ping failed:', error);
				}
			}
		}, 30000);

		return () => clearInterval(pingInterval);
	}, [ws]);

	return (
		<WebSocketContext.Provider value={ws}>
			{children}
		</WebSocketContext.Provider>
	);
};

export const useWebSocket = () => {
	const context = useContext(WebSocketContext);

	if (context === null) {
		// Automatically create a WebSocketProvider in the absence of a provided one
		console.warn(
			'WebSocketProvider is missing. Initializing default WebSocket context.',
		);

		// Return a default WebSocket instance with fallback logic
		return (() => {
			const isTestEnv = process.env.REACT_APP_ENV === 'test';

			const host = isTestEnv
				? 'localhost:1222'
				: process.env.REACT_APP_CENTRAL_SERVER;

			const wsProtocol = host.includes('localhost') ? 'ws://' : 'wss://';

			const defaultSocketUrl = `${wsProtocol}${host}`;

			const defaultSocket = new WebSocket(defaultSocketUrl);

			defaultSocket.onopen = () => {
				console.log('Default WebSocket connection established.');
			};

			defaultSocket.onclose = () => {
				console.log('Default WebSocket connection closed.');
			};

			defaultSocket.onerror = (error) => {
				console.error('Default WebSocket error:', error);
			};

			return defaultSocket;
		})();
	}

	return context;
};

import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../Layouts/StoreLayout';
import ProductCard from './components/ProductCard';
import PromotionCard from './components/promotioncard';
import AddressModal from './components/addressModal';
import {
	setActiveView,
	setSearchQuery,
	setSearchResults,
} from '../../store/otherslice';
import { useAPI } from '../../api/api_context';
import SectionHeader from './components/SectionHeader';
import { FaCaretDown } from 'react-icons/fa6';
import Button from './components/button';
import { cartOpen } from '../../store/cartSlice';
import { FaTimes } from 'react-icons/fa';
import { IoArrowBack } from 'react-icons/io5';

const StoreFront = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const apiService = useAPI();
	const promoScrollRef = useRef(null);
	const productsScrollRefs = useRef([]);
	const { userLocation, deliveryCost, eta } = useSelector(
		(state) => state.location,
	);
	const storeData = useSelector((state) => state.store);
	const { searchResults, activeView } = useSelector((state) => state.other);

	const [state, setState] = useState({
		cartItems: [],
		wishlist: [],
		openDropdownId: null,
		activeTab: 'all',
		filteredProducts: [],
		// searchResults: [],
		showAll: false,
		showAllFeatured: false,
		showAllProducts: false,
		isAddressModalOpen: false,
		selectedAddress: '',
		coupons: [],
		loading: true,
		error: null,
		cartSuccess: false,
		addedProduct: null,
	});

	// useEffect(() => {
	// 	if (!activeTab) {
	// 	  dispatch(setActiveTab('all'));
	// 	  dispatch(setActiveView('default'))
	// 	}
	//   }, [activeTab]);

	const parentRef = useRef(null);
	const [bannerWidth, setBannerWidth] = useState(0);

	useEffect(() => {
		const updateBannerWidth = () => {
			if (parentRef.current) {
				setBannerWidth(parentRef.current.offsetWidth);
			}
		};

		updateBannerWidth();
		window.addEventListener('resize', updateBannerWidth);

		return () => {
			window.removeEventListener('resize', updateBannerWidth);
		};
	}, []);

	useEffect(() => {
		if (
			!state.selectedAddress &&
			userLocation &&
			state.selectedAddress !== userLocation
		) {
			setState((prev) => ({ ...prev, selectedAddress: userLocation }));
		}
	}, [userLocation, state.selectedAddress]);

	const getAllProducts = useCallback(() => {
		return storeData?.categories?.flatMap(
			(category) => category.products || [],
		);
	}, [storeData?.categories]);

	const filterProducts = useCallback(() => {
		getAllProducts();
		dispatch(setActiveView(activeView));
	}, [activeView, getAllProducts, dispatch]);

	useEffect(() => {
		filterProducts();
	}, [filterProducts]);

	const getCoupons = useCallback(async () => {
		try {
			setState((prev) => ({ ...prev, loading: true }));
			const couponsData = await apiService.getCoupons();
			setState((prev) => ({
				...prev,
				coupons: couponsData.data,
				loading: false,
			}));
		} catch (error) {
			setState((prev) => ({
				...prev,
				error: 'Failed to load coupons.',
				loading: false,
			}));
			console.error('Error fetching coupons:', error);
		}
	}, [apiService]);

	useEffect(() => {
		getCoupons();
	}, [getCoupons]);

	const handleSeeAll = useCallback(
		(type, data) => {
			setState((prev) => ({
				...prev,

				selectedData: data,
				filteredProducts:
					type === 'promotions' ? data : data?.products || [],
			}));
			dispatch(
				setActiveView(
					type === 'promotions' ? 'all_promotions' : 'all_category',
				),
			);
		},
		[dispatch],
	);

	const resetView = useCallback(() => {
		setState((prev) => ({
			...prev,
			selectedCategory: null,
			selectedData: null,
			filteredProducts: [],
		}));

		dispatch(setActiveView('default'));
		dispatch(setSearchResults([]));
		dispatch(setSearchQuery([]));
	}, [dispatch]);

	const handleAddToCart = useCallback((product) => {
		setState((prev) => {
			const existingProduct = prev.cartItems.find(
				(item) => item._id === product._id,
			);
			if (existingProduct) {
				return {
					...prev,
					cartItems: prev.cartItems.map((item) =>
						item._id === product._id
							? { ...item, value: item.value + 1 }
							: item,
					),
				};
			} else {
				return {
					...prev,
					cartItems: [...prev.cartItems, { ...product, value: 1 }],
				};
			}
		});
	}, []);

	const handleCardClick = useCallback(
		(product, category) => {
			if (!category?.categorySlug || !product?._id) {
				console.error('Invalid product or category data', {
					product,
					category,
				});
				return;
			}

			navigate(
				`/${storeData.storeIdentifier}/${category.categorySlug[0]}/${product._id}`,
			);
		},
		[navigate, storeData.storeIdentifier],
	);

	const handleCartSuccess = useCallback((cartData) => {
		setState((prevState) => ({
			...prevState,
			cartSuccess: true,
			addedProduct: cartData,
		}));
		console.log(cartData);
	}, []);

	useEffect(() => {
		if (storeData?.categories) {
			productsScrollRefs.current = storeData.categories.map(
				(_, index) =>
					productsScrollRefs.current[index] || React.createRef(),
			);
		}
	}, [storeData?.categories]);

	const scroll = (ref, direction) => {
		if (ref?.current) {
			const scrollAmount = direction === 'left' ? -800 : 800;
			ref.current.scrollBy({
				left: scrollAmount,
				behavior: 'smooth',
			});
		} else {
			console.warn('Scroll ref is null or undefined', ref);
		}
	};

	useEffect(() => {
		console.log('Categories:', storeData.categories);
		console.log('Refs:', productsScrollRefs.current);
	}, [storeData.categories]);

	const handleTabClick = (tabSlug) => {
		setState((prevState) => ({
			...prevState,
			activeTab: tabSlug,
		}));
		console.log(tabSlug);
	};

	const tabStyles = useMemo(
		() => ({
			active: {
				backgroundColor: `${storeData?.settings?.buttonColor}0F`,
				borderLeft: `4px solid ${storeData?.settings?.buttonColor}`,
				borderRadius: '8px',
			},
			inactive: {
				backgroundColor: 'transparent',
				borderLeft: '4px solid transparent',
				color: 'gray',
				borderRadius: '8px',
			},
		}),
		[storeData?.settings?.buttonColor],
	);

	const infoItems = useMemo(
		() => [
			{
				label: 'Delivery Address',
				value: state.selectedAddress || userLocation,
				icon: (
					<i className='fi fi-sr-land-layer-location h-[18px] w-[18px] flex items-center justify-center'></i>
				),
				onClick: () =>
					setState((prev) => ({ ...prev, isAddressModalOpen: true })),
			},
			{
				label: 'Delivery Fee',
				value: `NGN ${deliveryCost}`,
				icon: (
					<i className='fi fi-ss-hand-holding-usd h-[18px] w-[18px] flex items-center justify-center'></i>
				),
			},
			{
				label: 'Delivery Time',
				value: `~ ${eta} minutes`,
				icon: (
					<i className='fi fi-br-biking-mountain h-[18px] w-[18px] flex items-center justify-center'></i>
				),
			},
		],
		[state.selectedAddress, userLocation, deliveryCost, eta],
	);

	return (
		<>
			<Layout
				storeName={storeData?.storeName}
				storeData={storeData}>
				{/* {isOrderModalOpen && <OrderModal isOpen={isOrderModalOpen} closeModal={closeModal}/>} */}
				<div
					className='md:mt-2 min-h-full w-full space-y-4 relative'
					ref={parentRef}>
					{state.cartSuccess && (
						<div
							className='fixed top-[70px] drop-shadow-sm z-40 flex justify-between bg-white border border-outline/50 rounded-lg p-3 items-center'
							style={{ width: `${bannerWidth}px` }}>
							<div className='flex space-x-3 items-center'>
								<img
									src={
										state.addedProduct.newProduct.images[0]
									}
									alt=''
									className='w-10 h-10 object-cover rounded-md'
								/>
								<div className='text-primary leading-none tracking-snug'>
									{state.addedProduct.newProduct.name} added
									to cart
								</div>
							</div>
							<div className='flex space-x-3 items-center'>
								<Button
									label={'Open Cart'}
									type='primary'
									size='S'
									onClick={() => {
										dispatch(cartOpen(true));
										setState((prev) => ({
											...prev,
											cartSuccess: false,
										}));
									}}
								/>
								<button
									className='rounded-full w-[32px] h-[32px] p-2 text-[13px] flex items-center justify-center text-gray-500 bg-formBG'
									onClick={() =>
										setState((prev) => ({
											...prev,
											cartSuccess: false,
										}))
									}>
									<FaTimes />
								</button>
							</div>
						</div>
					)}

					<div className='md:flex gap-4 place-content-end place-items-end hidden'>
						{infoItems.map((item, index) => (
							<div key={index}>
								<div
									className={`border border-outline/50 px-4 py-2 rounded-full bg-[#f9f9f9] mt-1 flex gap-2 ${
										item.onClick ? 'cursor-pointer' : ''
									}`}
									onClick={item.onClick}>
									<div className='text-[13px] flex space-x-3'>
										<div>{item.icon}</div>
										<p>{item.value}</p>
									</div>
									{item.onClick && <FaCaretDown />}
								</div>
							</div>
						))}
					</div>

					{storeData?.storeHeader && (
						<HeroImage storeData={storeData} />
					)}
					{/* <Hello /> */}
					<div className='flex-col block md:hidden bg-[#f9f9f9] p-4 mx-4'>
						<p className='text-[#7B8783]'>Delivery Options</p>

						<div className='flex items-center justify-between gap-2'>
							{infoItems.map((item, index) => (
								<React.Fragment key={index}>
									<div
										className={`${
											item.onClick
												? 'cursor-pointer'
												: 'text-sm'
										}`}
										onClick={item.onClick}>
										<p>
											{item.value.length > 10
												? `${item.value.substring(
														0,
														10,
												  )}...`
												: item.value}
											{/* {item.onClick && <FaCaretDown />} */}
										</p>
									</div>
									{index < infoItems.length - 1 && (
										<div className='h-2 w-2 rounded-full bg-[#AAB1AF]'></div>
									)}
								</React.Fragment>
							))}
						</div>
					</div>
					{/* mobile */}
					<div className='sm:hidden w-full py-2 flex overflow-x-auto px-4'>
						<div
							className={`whitespace-nowrap px-4 py-2  text-sm font-medium cursor-pointer ${
								state.activeTab === 'all'
									? `border-[${storeData?.settings?.buttonColor}] border-b-2 bg-red-500`
									: 'text-[#7B8783]'
							}`}
							onClick={() => {
								handleTabClick('all');
								resetView();
							}}>
							All Products
						</div>
						{storeData?.categories?.map((category) => (
							<div
								key={category._id}
								className={`whitespace-nowrap px-4 py-2 text-sm font-medium cursor-pointer ${
									state.activeTab === category.categorySlug
										? `border-[${storeData?.settings?.buttonColor}] border-b-2`
										: 'text-[#7B8783]'
								}`}
								onClick={() => {
									handleTabClick(category.categorySlug);
									handleSeeAll('category', category);
								}}>
								{category.name}
							</div>
						))}
					</div>
					{/* desktop */}
					<div className='grid md:grid-cols-5 md:gap-4 w-full px-4 md:px-0'>
						<div className='col-span-1 hidden sm:flex w-full flex-col space-y-3 sticky top-20 max-h-[calc(50vh-5rem)] overflow-y-auto'>
							<div
								style={
									state.activeTab === 'all'
										? tabStyles.active
										: tabStyles.inactive
								}
								className='px-4 py-3 cursor-pointer tracking-tight text-[15px]'
								onClick={() => {
									handleTabClick('all');
									resetView();
								}}>
								All Products
							</div>
							{storeData?.categories?.map((category) => (
								<div
									key={category._id}
									style={
										state.activeTab ===
										category.categorySlug
											? tabStyles.active
											: tabStyles.inactive
									}
									className='px-4 py-3 cursor-pointer tracking-snug leading-none text-[15px]'
									onClick={() => {
										handleTabClick(category.categorySlug);
										handleSeeAll('category', category);
									}}>
									{category.name}
								</div>
							))}
						</div>

						<div className='col-start-2 col-end-6 w-full overflow-hidden'>
							{activeView === 'default' && (
								<div className='space-y-6'>
									{/* Promotions */}
									<div className='space-y-2 '>
										<SectionHeader
											title='Promotions'
											showAll={state.showAll}
											onSeeAll={() =>
												handleSeeAll(
													'promotions',
													state.coupons.filter(
														(promo) => promo.active,
													),
												)
											}
											showAllCount={state.coupons.length}
											onScrollLeft={() =>
												scroll(promoScrollRef, 'left')
											}
											onScrollRight={() =>
												scroll(promoScrollRef, 'right')
											}
										/>

										{/* Promotions container */}
										<div
											ref={promoScrollRef}
											className='flex gap-4 overflow-x-scroll scrollbar-hide transition-all duration-300 ease-in-out'
											style={{
												scrollSnapType: 'x mandatory',
												// width: '100%',
											}}>
											{state.coupons.length > 0 ? (
												state.coupons
													.filter(
														(promo) => promo.active,
													)
													.map((promo) => (
														<div
															key={promo._id}
															className='flex-shrink-0 w-[calc(100%-12px)] md:w-[calc(33.3%-10px)] no-scrollbar'
															style={{
																scrollSnapAlign:
																	'start',
															}}>
															<PromotionCard
																code={
																	promo.code
																}
																discountValue={
																	promo.discountValue
																}
																expiry={
																	promo.expiry
																}
																maxUsage={
																	promo.maxUsage
																}
																active={
																	promo.active
																}
																couponType={
																	promo.couponType
																}
															/>
														</div>
													))
											) : (
												<p className='col-span-full text-center text-gray-500 py-8'>
													No active promotions
													available at the moment.
												</p>
											)}
										</div>
									</div>

									{/* Products Listing */}
									<div className='space-y-8'>
										{storeData?.categories?.map(
											(category, index) => (
												<div
													key={category._id}
													className='space-y-2'>
													{/* Section Header */}
													<SectionHeader
														title={category.name}
														showAll={state.showAll}
														onSeeAll={() =>
															handleSeeAll(
																'category',
																category,
															)
														}
														showAllCount={
															category.products
																.length
														}
														onScrollLeft={() =>
															scroll(
																productsScrollRefs
																	.current[
																	index
																],
																'left',
															)
														}
														onScrollRight={() =>
															scroll(
																productsScrollRefs
																	.current[
																	index
																],
																'right',
															)
														}
													/>

													{/* Products under each Category */}
													<div
														ref={
															productsScrollRefs
																.current[index]
														}
														className='flex gap-4 overflow-x-scroll scrollbar-hide transition-all duration-300 ease-in-out'
														style={{
															scrollSnapType:
																'x mandatory',
														}}>
														{category.products.map(
															(product) => (
																<div
																	key={
																		product._id
																	}
																	className='flex-shrink-0 md:w-[calc(25%-12px)] w-[calc(50%-12px)] no-scrollbar'
																	style={{
																		scrollSnapAlign:
																			'start',
																	}}>
																	<ProductCard
																		product={
																			product
																		}
																		category={
																			category
																		}
																		onCardClick={
																			handleCardClick
																		}
																		onAddToCart={
																			handleAddToCart
																		}
																		setCartSuccessNotifier={
																			handleCartSuccess
																		}
																	/>
																</div>
															),
														)}
													</div>
												</div>
											),
										)}
									</div>
								</div>
							)}

							{(activeView === 'all_promotions' ||
								activeView === 'all_category') && (
								<div className='col-span-5 space-y-4'>
									<button
										className='flex items-center gap-2 text-primary'
										onClick={resetView}>
										<IoArrowBack />
										Back
									</button>
									<h2 className='text-2xl font-semibold mb-4'>
										{activeView === 'all_promotions'
											? 'All Promotions'
											: state.selectedData?.name}
									</h2>
									{activeView === 'all_promotions' && (
										<div className='grid md:grid-cols-3 gap-4'>
											{state.filteredProducts.map(
												(promo) => (
													<PromotionCard
														key={promo._id}
														code={promo.code}
														discountValue={
															promo.discountValue
														}
														expiry={promo.expiry}
														maxUsage={
															promo.maxUsage
														}
														active={promo.active}
														couponType={
															promo.couponType
														}
													/>
												),
											)}
										</div>
									)}

									{activeView === 'all_category' && (
										<div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
											{state?.filteredProducts.map(
												(product) => (
													<ProductCard
														key={product._id}
														product={product}
														category={
															state.selectedData
														}
														onCardClick={
															handleCardClick
														}
														onAddToCart={
															handleAddToCart
														}
														setCartSuccessNotifier={
															handleCartSuccess
														}
													/>
												),
											)}
										</div>
									)}
								</div>
							)}
							{activeView === 'search_results' && (
								<div>
									<button
										className='flex items-center gap-2 text-primary'
										onClick={resetView}>
										<IoArrowBack />
										Back
									</button>
									<h2 className='text-2xl font-semibold mb-4'>
										{activeView === 'all_promotions'
											? 'All Promotions'
											: state.selectedData?.name}
									</h2>
									<div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
										{searchResults.map((product) => (
											<ProductCard
												key={product._id}
												product={product}
												category={state.selectedData}
												onCardClick={handleCardClick}
												onAddToCart={handleAddToCart}
												setCartSuccessNotifier={
													handleCartSuccess
												}
											/>
										))}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				{state.isAddressModalOpen && (
					<AddressModal
						isOpen={state.isAddressModalOpen}
						onClose={() =>
							setState((prev) => ({
								...prev,
								isAddressModalOpen: false,
							}))
						}
						onSelectAddress={(address) =>
							setState((prev) => ({
								...prev,
								selectedAddress: address,
								isAddressModalOpen: false,
							}))
						}
						initialAddress={state.selectedAddress || userLocation}
					/>
				)}
				<style jsx='true'>{`
					.scrollbar-hide::-webkit-scrollbar {
						display: none;
					}
					.scrollbar-hide {
						-ms-overflow-style: none;
						scrollbar-width: none;
					}
				`}</style>
			</Layout>
		</>
	);
};

const HeroImage = ({ storeData }) => (
	<div className='relative h-[200px] overflow-hidden'>
		<img
			src={storeData.storeHeader}
			alt={`${storeData.storeName}'s Hero`}
			className='w-full h-full object-cover md:rounded-xl'
		/>
	</div>
);

export default React.memo(StoreFront);

import React from 'react';
import ModalHeader from './modalHeader';
import { toast } from 'sonner';
import Button from './button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../../../store/cartSlice';
import bagGIF from '../../../assets/images/bag.gif';

const OrderSuccessModal = ({
	pin,
	customerEmail,
	modalTimer,
	orderId,
	orderTrackingLink,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { storeIdentifier } = useSelector((state) => state.store);

	const handleCopyOrderId = () => {
		navigator.clipboard.writeText(orderId);
		toast('Order ID copied');
	};
	const handleContinueShopping = () => {
		dispatch(clearCart());
		const currentPath = window.location.pathname;
		const targetPath = `/${storeIdentifier}`;
		if (currentPath !== targetPath) {
			navigate(targetPath);
		}
	};

	return (
		<div>
			<ModalHeader
				// payment={true}
				// header='Payment method'
				onClick={handleContinueShopping}
			/>
			<div className='p-6 flex gap-6 flex-col'>
				<div className='flex flex-col items-center justify-center gap-2'>
					<img
						src={bagGIF}
						alt='Payment Successful'
						className='w-[120px] h-[120px]'
					/>
					<h1 className='font-medium text-[21px] text-primary'>
						Order Placed Successfully
					</h1>
					<p className='text-center text-[14px] text-secondary'>
						Thanks for shopping with us. We’ve sent an email with
						your order summary to{' '}
						<span className=''>{customerEmail}</span>
					</p>
				</div>
				<div className='flex flex-col gap-4 justify-center items-center bg-formBG border-gray-100 border px-4 py-8 rounded-md'>
					<div className='flex flex-col gap-1 justify-center items-center'>
						<h2 className='text-secondary'>Your order Id</h2>
						<div className='flex items-center gap-2 justify-center'>
							<p>{orderId}</p>
							<button
								onClick={handleCopyOrderId}
								className='text-sm text-basegreen underline'>
								copy
							</button>
						</div>
					</div>
					<div className='flex flex-col gap-1 justify-center items-center'>
						<h2 className='text-secondary'>Your delivery pin</h2>
						<div className='flex justify-center items-center gap-2'>
							{pin.map((digit, index) => (
								<input
									key={index}
									type='text'
									value={digit}
									readOnly
									className='w-[64px] h-[64px] text-center border-none rounded-[8px] p-[10px] bg-[#F5F5F5] focus:outline-none  leading-[21px]  text-primary'
								/>
							))}
						</div>
					</div>
				</div>

				<div className=' flex flex-col w-full items-center gap-6 place-content-end '>
					<Button
						label='Continue Shopping'
						onClick={handleContinueShopping}
						size='M'
						className='w-full'
					/>
					<a
						href={orderTrackingLink}
						target='_blank'
						rel='noreferrer'
						className='text-basegreen text-md underline'>
						Track Order
					</a>
				</div>

				{/* <p className='text-center mb-[40px]  text-[14px] text-secondary'>
					This modal will close automatically in{' '}
					<span className='text-basegreen'>{modalTimer} seconds</span>
					.
				</p> */}
			</div>
		</div>
	);
};

export default OrderSuccessModal;

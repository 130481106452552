import React, { useState } from 'react';
import ProductModal from './productModal';

const ProductCard = ({
	product,
	category,
	onCardClick,
	storeData,
	setCartSuccessNotifier,
}) => {
	const [showModal, setShowModal] = useState(false);

	const handleAddToCart = () => {
		setShowModal(true);
	};

	const handleAddToCartSuccess = (data) => {
		setCartSuccessNotifier(data); // Pass data to parent
	};

	return (
		<div className=''>
			<div
				className='relative rounded-md cursor-pointer'
				onClick={() =>
					product.availableQuantity >= 1 &&
					onCardClick(product, category)
				}>
				{product.images && (
					<div className='relative'>
						<img
							src={product.images?.[0]}
							alt={product.name}
							className='w-64 h-48 object-cover rounded-lg'
						/>
					</div>
				)}
				<div className='pt-3'>
					<div className='flex font-light justify-between items-center'>
						<span className='text-[13px]'>
							NGN {product.unitPrice.toLocaleString()}
						</span>
					</div>
					<h2 className='font-medium text-primary mt-1 mb-1 leading-[28px] h-[48px]'>
						{product.name}
					</h2>
				</div>
			</div>
			<div className='items-center flex text-[15px] mt-6'>
				{product.availableQuantity >= 1 ? (
					<button
						className='w-full justify-center border border-basegreen rounded-lg flex items-center gap-2 py-3 text-basegreen hover:bg-basegreen hover:text-white'
						onClick={handleAddToCart}>
						<i className='fi fi-br-shopping-cart-add flex items-center justify-center'></i>
						Add to cart
					</button>
				) : (
					<button
						className='text-[#CA1616] bg-[#FFF0F0] rounded-lg px-2 py-3 w-full'
						disabled>
						Out of Stock
					</button>
				)}
			</div>
			{showModal && (
				<ProductModal
					product={product}
					category={category}
					storeData={storeData}
					onClose={() => setShowModal(false)}
					onAddToCartSuccess={handleAddToCartSuccess}
				/>
			)}
		</div>
	);
};

export default ProductCard;
